import React from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import BrandsSection from "../Section/BrandsSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import BlogSection from "../Section/BlogSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Wellness Program",
    subTitle:
      "We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Health Care",
    subTitle:
      "We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Medical Treatment",
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Nutrition Program",
    subTitle:
      "We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Long-Term Care",
    subTitle:
      "We believe in working collaboratively with our team membersvand other healthcare professionals to provide comprehensive and effective care to our patients.",
  },
];
const brandData = [{ imgUrl: "", imgAlt: "Brand" }];
const faqData = [
  {
    title: "What services does NHWM offer?",
    content:
      "NHWM guides you in preventing chronic disease and enhancing your overall wellness. Our comprehensive services, from health consultations and fitness programs to nutritional counseling and mental health support, provide you with the tools for a healthy life.",
  },
  {
    title: "How do I schedule an appointment with NHWM?",
    content:
      "You can simply call us at our hotline number or book an appointment online. Our team will be happy to assist you in scheduling a convenient time for your health session.",
  },
  {
    title: "Is is possible to consult with a specialist online?",
    content:
      "Yes it is possible to consult with a specialist online. You can book an online consultation with our Health professionals and receive the care you need from the comfort of your home.",
  },
  {
    title: "How health checkup works with chornic disease?",
    content:
      "Our health checkup program is designed to help you manage chronic diseases and improve your overall health. Our team of specialists will work with you to create a personalized treatment plan that meets your unique needs and goals.",
  },
  {
    title: "How the Free Health Session works?",
    content:
      "We are concerned about your health and well-being. Our free health sessions are designed to provide you with the information and resources you need to make informed decisions about your health. Our team of experts will be available to answer any questions you may have and provide you with the support you need to take control of your health.",
  },
];
const blogData = [
  {
    title:
      "Next Free Health Session in Guwahati, Assam in coming September 2024",
    thumbUrl: "images/nhwm/health_event.jpg",
    date: "September 1, 2024",
    btnText: "Learn More",
    href: "/",
    socialShare: true,
  },
  {
    title: "Leran More About Our Health & Wellness Program",
    thumbUrl: "images/nhwm/wellness_program.jpg",
    date: "September 9, 2024",
    btnText: "Learn More",
    href: "/",
    socialShare: true,
  },
  {
    title: "Importance of Regular Health Checkup & Analysis",
    thumbUrl: "images/nhwm/regular_checkup.jpg",
    date: "September 14, 2024",
    btnText: "Learn More",
    href: "/",
    socialShare: true,
  },
];
const awardData = [
  {
    title: "Free Health Sessions",
    subTitle:
      "This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Health Checkup & Analysis",
    subTitle:
      "This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Intensive Long-term care & Lifestyle Disease",
    subTitle:
      "This recognition is given to hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Medical Consultation & Treatment",
    subTitle:
      "This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.",
    iconUrl: "images/icons/award.svg",
  },
];
const departmentData = [
  {
    title: "Emergency Department",
    iconUrl: "images/home_1/department_icon_1.svg",
    href: "/departments/department-details",
  },
  {
    title: "Pediatric Departement",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/departments/department-details",
  },
  {
    title: "Gynecology Department",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/departments/department-details",
  },
  {
    title: "Cardiology Department",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/departments/department-details",
  },
  {
    title: "Neurology Department",
    iconUrl: "images/home_1/department_icon_5.svg",
    href: "/departments/department-details",
  },
  {
    title: "Psychiatry Department",
    iconUrl: "images/home_1/department_icon_6.svg",
    href: "/departments/department-details",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Your Partner in Health and Wellness"
        subTitle="Our dedicated specialists provide personalized care and innovative solutions to cure disease and guide you on your journey to optimal health and a fulfilling life."
        bgUrl="/images/nhwm/bg_cover.jpg"
        imgUrl="/images/home_1/hero_img.jpg"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: "Hotline",
            subTitle: "+91 361 796 80 83",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Immediate Assistance",
            subTitle: "+91 361 796 80 83",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Location",
            subTitle: "Guwahati, Assam",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
        btnText="Book Free Session"
        btnUrl="/appointments"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Services" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/nhwm/about_us_home.jpg"
          spiningImgUrl="/images/nhwm/logo.png"
          title="About Us"
          subTitle="Health & Wellness Program"
          featureList={[
            {
              featureListTitle:
                "NHWM is a team of experienced Health & Wellness medical professionals",
              featureListSubTitle:
                "Welcome to a journey where your health and well-being take center stage. At NHWM, we embrace a holistic approach, integrating physical, mental, and emotional wellness to help you live a balanced, fulfilling life. Our dedicated team provides personalized care and innovative solutions tailored to your needs.Whether you need preventive care, chronic disease management, or wellness enhancement, we are here to guide you. Our comprehensive services include medical consultations, fitness programs, nutritional counseling, and mental health support.Join us in creating a healthier, happier you. Together, we can build a path to optimal health and wellness. Your well-being is our priority, and we are honored to be your partner on this journey.",
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Our Experties"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Available Services" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of Our Customers"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/nhwm/checkup.jpg"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Book your Free health session today that will be conduct in your area by our specialist!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="Free Sessions"
          data={blogData}
        />
      </Section>
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Registration"
          sectionTitleUp="Book Your Free Health Session"
          imgUrl="/images/nhwm/registration.jpg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        {/* <BrandsSection data={brandData} /> */}
      </Section>
      {/* End Brand Section */}
    </>
  );
}
