import React from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import TeamSectionStyle2 from "../Section/TeamSection/TeamSectionStyle2";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
const teamData = [
  {
    imgUrl: "/images/doctors/doctor_1.png",
    department: "Emergency Department",
    name: "Expert Name",
    designation: "Health and Wellness Specialist",
    description: "Expert Description",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    category: "cardiology",
    href: "/doctors/",
  },
  {
    imgUrl: "/images/doctors/doctor_2.png",
    department: "Emergency Department",
    name: "Expert Name",
    designation: "Health and Wellness Specialist",
    description: "Expert Description",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    category: "emergency",
    href: "/doctors/",
  },
  {
    imgUrl: "/images/doctors/doctor_3.png",
    department: "Emergency Department",
    name: "Expert Name",
    designation: "Health and Wellness Specialist",
    description: "Expert Description",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    category: "pediatric",
    href: "/doctors/",
    // href: "/doctors/doctor-details",
  },
];

export default function Doctors() {
  pageTitle("Doctors");
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/nhwm/experts.jpg"
        title="Introduce You to <br />Our Experts"
        subTitle="The list of certified Health wellness Experts with years of <br />professional experiences"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/nhwm/about_bg.jpg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
}
